import React from 'react';

import Image from 'next/image';

import { AppRatingSection } from '../../../../components/AppRatingSection/AppRatingSection';
import { BragBarList } from '../../../../components/BragBar/BragBarList';
import { AppStoreButtons } from '../../../../components/Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { Container } from '../../../../components/Container/Container';
import { Heading } from '../../../../components/Heading/Heading';
import { QRCode } from '../../../../components/QRCode/QRCode';
import phonePreview from '../../assets/header/phonePreview.png';
import headerWaveMobile from '../../assets/header/headerWaveMobile.svg';
import headerWaveTablet from '../../assets/header/headerWaveTablet.svg';

export const HomePageHeaderSection = (): JSX.Element => {
  return (
    <section
      className="col-start-1 row-start-1 grid grid-cols-1 grid-rows-1 pt-4 md:mx-28 md:mt-40"
      data-testid="HomePageHeaderSection"
    >
      <div className="col-start-1 row-start-1 flex flex-col justify-end md:hidden">
        <Image
          className="w-full sm:hidden"
          src={headerWaveMobile}
          alt="depth background centre mobile"
        />
        <Image
          className="hidden w-full sm:block"
          src={headerWaveTablet}
          alt="depth background centre desktop"
        />
      </div>
      <Container className="col-start-1 row-start-1 flex flex-col md:flex-row md:justify-around">
        <div className="w-full md:w-3/5 lg:w-1/2">
          <Heading variant="xl" as="h1" className="mb-2 sm:w-4/6 md:w-full">
            Find your best fishing spot with Fishbrain
          </Heading>
          <Heading variant="2xs" as="h2" className="text-abyss">
            All the intel you need to catch more fish
          </Heading>
          <div className="mt-3 flex flex-col md:mt-6 md:flex-row md:items-center md:gap-x-6">
            <QRCode source="homePageHeaderSection" />
            <div className="flex flex-col md:flex-row-reverse md:gap-x-12">
              <AppRatingSection />
              <div className="flex justify-start sm:mt-4 md:mt-0">
                <AppStoreButtons source="homePageHeaderSection" orientation="vertical" />
              </div>
            </div>
          </div>
        </div>

        <div className="my-6 flex w-full justify-end sm:-mt-52 md:-mt-40 md:w-2/5 lg:-mt-20 lg:w-1/2">
          <Image
            className="object-contain sm:w-4/6 md:w-full"
            src={phonePreview}
            priority
            alt="App preview image"
            sizes="(min-width: 1680px) 750px,
                  (min-width: 990px) calc(40vw - 14rem),
                  (min-width: 600px) 67vw,
                  100vw"
          />
        </div>
      </Container>
      <BragBarList />
    </section>
  );
};
